import React from 'react';

import { useNavigate } from 'react-router-dom';


// material-ui
import { useTheme } from '@mui/material/styles';
import { Fade, Button, ClickAwayListener, Paper, Popper, List, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';

// assets
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
// import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
// import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';

// ==============================|| PROFILE SECTION ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();


  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if(index === 1) {
      handleToggle();
      navigate('/user-profile');
    } else if(index === 2) {
      handleToggle();
      navigate('/contact-info');
    } else if(index === 3) {
      handleToggle();
      navigate('/training-mode');
     }// else if(index === 4) {
    //   handleToggle();
    //   navigate('/bookings');
    // }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        sx={{ minWidth: { sm: 50, xs: 35 } }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="Profile"
        onClick={handleToggle}
        color="inherit"
      >
        <AccountCircleTwoToneIcon sx={{ fontSize: '1.5rem', color:'blue' }} />
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 350,
                    minWidth: 250,
                    backgroundColor: theme.palette.background.paper,
                    pb: 0,
                    borderRadius: '10px'
                  }}
                >
                  <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                    <ListItemIcon>
                      <PermIdentityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                  </ListItemButton>
                  <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
                    <ListItemIcon>
                      <ContactMailOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contact Info" />
                  </ListItemButton>

                  <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
                    <ListItemIcon>
                      <FavoriteBorderOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Trainer Mode" />
                  </ListItemButton>
                  {/* <ListItemButton selected={selectedIndex === 4} 
                  onClick={(event) =>
                   handleListItemClick(event, 4)}>
                    <ListItemIcon>
                      <BookmarkOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bookings" />
                  </ListItemButton> */}
                  <ListItemButton selected={selectedIndex === 5}>
                    <ListItemIcon>
                      <PowerSettingsNewOutlinedIcon />
                    </ListItemIcon>
                    
                    <ListItemButton onClick={()=> navigate("/login")} > 
                    <ListItemText primary="Logout"/>
                      </ListItemButton>
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
