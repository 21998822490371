import React, { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from '../component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('../views/Dashboard')));

const UtilsTypography = Loadable(lazy(() => import('../views/Utils/Typography')));

const SearchTrainers = Loadable(lazy(() => import('../views/SearchTrainers')));

const ProfileDashboard = Loadable(lazy(() => import('../views/ProfileDashboard')));

const UserProfile = Loadable(lazy(() => import('../views/UserProfile')));

const ChangePassword = Loadable(lazy(()=> import('../views/ChangePassword')));

const AboutMe = Loadable(lazy(()=> import('../views/AboutMe')));

const BackgroundCheck = Loadable(lazy(()=> import('../views/BackgroundCheck')));

const ContactInfo = Loadable(lazy(()=> import('../views/ContactInfo')));

const Settings = Loadable(lazy(()=> import('../views/Settings')));

const Payments = Loadable(lazy(()=> import('../views/Payments')));

const Bookings = Loadable(lazy(()=> import('../views/Bookings')));

const Notifications = Loadable(lazy(()=> import('../views/Notifications')));

const SendEmail = Loadable(lazy(()=> import('../views/SendEmail')));

const InviteFriend = Loadable(lazy(()=> import('../views/InviteFriend')));

const TrainingMode = Loadable(lazy(()=> import('../views/TrainingMode')));

const CoursesCard = Loadable(lazy(()=> import('../views/UserProfile/CoursesCard')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard/default',
      element: <DashboardDefault />
    },
    { path: '/utils/util-typography', element: <UtilsTypography /> },
    { path: '/search-trainers', element: <SearchTrainers /> },
    { path: '/profile-dashboard', element: <ProfileDashboard /> },
    { path: '/user-profile', element: <UserProfile />},
    { path: '/change-password', element: <ChangePassword />},
    { path: '/about-me', element: <AboutMe />},
    { path: '/background-check', element: <BackgroundCheck />},
    { path: '/Contact-info', element: <ContactInfo/>},
    { path: '/Settings', element: <Settings />},
    { path: '/payments', element: <Payments />},
    { path: '/bookings', element: <Bookings />},
    { path: '/send-email', element: <SendEmail />},
    { path: '/notifications', element: <Notifications />},
    { path: '/invite-friend', element: <InviteFriend />},
    { path: '/training-mode', element: <TrainingMode />},
    { path: '/user-profile/courses', element: <CoursesCard />}

  ]
}

export default MainRoutes;